.Community-Box {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    padding: 15px 0;
    margin: 15px 0;
    clear: both;
    background: #191919;
}

.Community-Box textarea {
    border: 1px solid #ff3300;
    background: rgba(41,44,49,.4);
    color: white;
    display: block;
    width: 80%;
    margin: 0 auto;
    min-height: 4rem;
    border-radius: 2px;
}

.Community-Box button {
    border: 1px solid #ff3300;
    background: rgba(41,44,49,.4);
    color: white;
    display: block;
    margin: 0.5rem auto;
    border-radius: 2px;
    box-shadow: 2px 2px 2px #333;
    padding: 0.4rem 1rem;
}

.Community-Box .Community-Box-Title {
    color: #ff3300;
    margin-top: 0;
    padding-bottom: 10px;
    padding-left: 15px;
    text-align: left;
}

.Community-Box .Write-Comment-Wrapper:focus {
    height: 8rem;
}

.Community-Box .Write-Comment-Wrapper {
    clear: both;
    padding-top: 1rem;
    resize: vertical;
    min-width: 450px;
    min-height: 79px;
}

.Community-Box .Comment-Wrapper div:has(> .Loading-logo) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Community-Box .Comment-Wrapper {
    clear: both;
    border: 2px solid #383838;
    border-top-width: 50px;
    border-bottom-width: 25px;
    margin: 10px 8px;
    position: relative;
    padding: 5px;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar .Comment-Author-Profile-Details {
    position: absolute;
    top: -45px;
    right: 5px;
    height: 40px;
    width: 50%;
    text-align: right;
    color: var(--pelcom-red);
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar .Comment-Author-Profile-Details > div:first-child {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar > div:first-child img {
    max-width: 40px;
    max-height: 40px;
}

.Community-Box .Comment-Wrapper .Comment-Time {
    position: absolute;
    left: 5px;
    bottom: -20px;
    user-select: none;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar .Comment-Community-Actions {
    position: absolute;
    bottom: -30px;
    right: 0;
    user-select: none;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar .Comment-Community-Actions button:active {
    box-shadow: 0 0 2px #000;
    color: #fff;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar .Comment-Community-Actions button {
    background: none !important;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    margin-right: 2px;
    padding: 2px 6px;
    display: inline-block;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar .Comment-Community-Actions button:disabled {
    color: #ccc !important;
    box-shadow: none;
    cursor: not-allowed;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    box-shadow: 1px 1px 2px black;
}

.Community-Box .Comment-Wrapper .Comment-Info-Sidebar {
    font-size: 10px;
    line-height: 12px;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    text-align: center;
}

a.Comment-Link-Anchor {
    position: relative;
    top: -20px;
}

.User-Comment-Text {
    white-space: break-spaces;
    word-break: break-word;
}

.User-Comment-Text a:any-link {
    color: #fc361d;
}

.Content-Column .Community-Box:first-child {
    margin-top: -25px;
    margin-bottom: 20px;
}

.Content-Column .Community-Box:first-child p.Loading-logo {
    max-width: 50px;
    margin: 0 auto;
}

@media (max-width: 767px) {
    .Community-Box .Write-Comment-Wrapper {
        min-width: 300px;
    }

    .Community-Box .Comment-Wrapper .Comment-Info-Sidebar .Comment-Author-Profile-Details {
        width: 100%;
    }
}

