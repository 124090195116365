.Broadcast-Box {
    position: relative;
    border-radius: 4px;
    margin-bottom: 20px;
    min-height: auto;
}

.Broadcast-Box-Wrapper {
    background: #191919;
    padding: 10px 0;
}

.Broadcast-Box-Wrapper.With-Margins {
    margin: 15px 0;
}

.Broadcast-Box-Title {
    color: #ff3300;
    padding-bottom: 10px;
    margin: 0 15px;
    text-align: left;
    font-size: 20px;
    border-bottom: 1px solid #565656;
}

.Broadcast-Box .Label a {
    color: white;
    text-decoration: none;
    box-sizing: border-box;
}

.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast .Label.Broadcast-Duration {
    display: block;
    left: 5px;
    position: absolute;
    top: 5px;
    border-radius: 0;
    background: rgba(50, 50, 50, 0.5);
}

.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast .Label.Broadcast-Date {
    display: block;
    left: auto;
    position: absolute;
    right: 5px;
    top: calc((63vw - 2rem) * 0.33333 * 9/16 - 45px);
    border-radius: 0;
    background: rgba(50, 50, 50, 0.5);
}

.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast span {
    color: white;
    display: inline;
    font-size: 14px;
    font-weight: 700;
    vertical-align: top;
}

.Broadcast-Box h2 span:nth-child(2) {
    font-size: medium;
}

.Broadcast-Box h2 span:nth-child(n+3) {
    font-size: small;
}

.Broadcast-Box h2 span:nth-child(3) {
    width: 100%;
    display: inline-block;
    text-align: left;
    position: absolute;
    left: 3px;
    bottom: 0;
}

.Broadcast-Box h2 span:nth-child(4) {
    width: 100%;
    display: inline-block;
    text-align: right;
    position: absolute;
    right: 3px;
    bottom: 0;
}

.Broadcast-Box h2 span:nth-child(n+5) {
    display: none;
}

/*.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast span:first-child {
    top: 5px;
}

.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast span:nth-child(2) {
    top: unset;
    bottom: 25px;
}

.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast span:nth-child(n+3) {
    display: none;
}*/

.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast a {
    display: block;
    position: relative;
    margin-top: calc((63vw - 2rem) * 0.33333 * 9/16 - 7px);
    left: 5px;
    background: none;
    width: calc(100% - 10px);
    min-height: 30px;
    top: 0;
    text-align: left;
    line-height: 22px;
    text-decoration: none;
}

.Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast {
    color: white;
    background: #0E0E10;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: calc((63vw - 2rem) * 0.33333 * 9/16);
    position: relative;
    width: calc(33.33333333% - 10px);
    box-sizing: border-box;
    padding: 0;
    margin: 0 5px 15px 5px;
    cursor: pointer;
    user-select: none;
}

.Broadcast-Box .Broadcast-Box-Past {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.Broadcast-Box .Broadcast-Box-Live {
    position: absolute;
    left: 20px;
    top: 20px;
    display: block;
}

.Broadcast-Box .Label svg {
    margin-left: 0.3rem;
}

.Broadcast-Box h2 small svg {
    margin-right: 0.3rem;
}

.Broadcast-Box h2 small {
    color: #ff3300;
    font-size: 50%;
    line-height: 1rem;
    cursor: pointer;
    pointer-events: none;
    font-weight: 400;
}

.Broadcast-Box h2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    text-shadow: 3px 3px 3px black;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    pointer-events: none;
    word-wrap: break-word;
    font-size: 30px;
    line-height: 1.1;
    margin-top: 80px;
    height: 103px;
    margin-bottom: 0;
}

.Broadcast-Box-Cover-Image {
    width: 100%;
    height: 187px;
    object-fit: cover;
    object-position: 50% 50%;
    vertical-align: middle;
    border: 0;
    box-sizing: border-box;
}


@media (max-width: 767px) {
    .Broadcast-Box .Broadcast-Box-Past .Broadcast-Box-Past-Broadcast {
        padding-top: 40px;
    }

    .Broadcast-Box h2 {
        font-size: 23px;
        height: auto;
        left: 0;
        margin-top: 0;
        top: unset;
        bottom: 0;
    }
}
