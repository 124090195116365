
div[data-component="DC:ReservationCalendar"] {
    width: 100%;
    max-width: 250px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.5);
    font-size: 0;
}

.ck-editor div[data-component="DC:ReservationCalendar"] {
    font-size: 12px;
    border: 1px dashed red;
    margin: 5px;
}

div.Reservation-Calendar, div.Reservation-Calendar div {
    padding: 10px 0;
}

.SideBar .Reservation-Calendar div {
    margin-top: 0;
}

.Reservation-Calendar-Month-View {
    width: 100%;
    user-select: none;
    z-index: 100;
    position: relative;
}

.Reservation-Calendar-Month-View td {
    background: rgba(128, 128, 128, 0.5);
    color: black !important;
    text-align: center;
    padding: 6px 0;
    position: relative;
}

.Reservation-Calendar-Month-View thead th.Navigation-Action {
    cursor: pointer;
}

.Reservation-Calendar-Month-View colgroup col {
    width: calc(1 / 7* 100%);
    min-width: 15%;
    max-width: 15%;
}

.Reservation-Calendar-Month-View thead th {
    background: transparent;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    color: white !important;
}

.Reservation-Calendar-Month-View thead th.Month-Header {
    background: #383838;
}

.Reservation-Calendar-Month-View td.Current-Month-Day {
    background: #ddffcc;
    color: black !important;
    cursor: pointer;
}

.Reservation-Calendar-Month-View td.Next-Month-Day {
    background: rgba(221, 255, 204, 0.8);
    color: #666 !important;
    cursor: pointer;
}

.Reservation-Calendar-Month-View td.Current-Month-Day.Day-Is-Today {
    font-weight: bold;
    text-decoration: underline;
}

.Reservation-Calendar-Month-View td.Reserved-Day {
    background: #f30 !important;
    cursor: not-allowed;
}

.Reservation-Calendar-Container label {
    font-weight: bold;
}

.Reservation-Calendar-Container .Flex-Column {
    max-width: 65%;
    width: 65%;
    font-size: clamp(11px, 12px, 13px);
}

.Reservation-Calendar-Container .Flex-Column:first-child {
    max-width: 35%;
    width: 35%;
}

.Reservation-Calendar-Month-View + .Flex-Row .Flex-Column {
    width: 50%;
    max-width: 50%;
    font-size: clamp(11px, 12px, 13px);
}

.Reservation-Calendar-Container .Flex-Column input {
    width: 100%;
}

.Reservation-Calendar-Container .Flex-Column input[type="checkbox"] {
    width: auto;
}

.Reservation-Calendar-Container .Flex-Column label:has(> input[type="checkbox"]) {
    display: block;
}

div#fskey-user-icon {
    display: none !important;
}

.Reservation-Calendar-Container .Flex-Column:only-child {
    width: 100%;
    max-width: 100%;
}

.Reservation-Calendar-Container .Flex-Column textarea {
    display: block;
    width: 100%;
    resize: vertical;
}

.Reservation-Calendar-Container {

}

.Reservation-Calendar-Month-View td.Current-Month-Day.Day-Passed {
    background: transparent;
    color: #eee !important;
}

.Reservation-Calendar-Month-View td.Out-of-Active-Selection, .Reservation-Calendar-Month-View td.Blocked-Selection {
    opacity: 0.5;
}

.Reservation-Calendar-Month-View td.Blocked-Selection {
    cursor: not-allowed;
}

.Reservation-Calendar-Month-View td.Current-Month-Day.Day-Passed::before {
    background: rgba(128, 128, 128, 0.5);
    content: "";
    cursor: not-allowed;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Reservation-Calendar-Month-View td.Next-Month-Day[data-day="1"]::before {
    color: white;
    content: attr(data-month);
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    text-transform: uppercase;
    font-size: 6px;
    padding: 0 2px;
    background: #383838;
    z-index: 100;
    min-width: calc(100% - 4px);
    white-space: nowrap;
}

.Reservation-Calendar input[type="date"][value*="2"]::before {
    content: attr(value);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #191919;
    color: white;
    z-index: 100;
}

.Reservation-Calendar input[type="date"][value*="2"] {
    position: relative;
    border: none;
}

.Reservation-Calendar .Reservation-Slider {
    filter: grayscale(1);
    width: 100%;
    margin: 2px 4px;
}

.Reservation-Calendar .Reservation-Submitting button.Full-Width-Button:disabled {
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275) all;
    color: #666;
}

.Reservation-Calendar .Reservation-Submitting button.Full-Width-Button:disabled::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    position: absolute;
    background: var(--PelComLogo);
    background-position: top left;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    animation: Loading-logo-spin infinite 3s linear;
}

.Reservation-Calendar .Reservation-Terms-Info {
    padding-top: 8px;
    display: inline-block;
}

.Reservation-Calendar .Reservation-Terms-Info a:any-link, .Reservation-Calendar label a:any-link, .Reservation-Calendar strong {
    color: var(--pelcom-red);
}

div.Reservation-Calendar div.Reservation-Actions-Wrapper {
    padding-top: 10px;
}

.Reservation-Calendar .Reservation-Information {
    transition: filter 0.25s linear 0s, marginTop 0.25s linear 0s, opacity 0.15s linear 0.1s, zIndex 0.15s linear 0.1s;
    filter: blur(0) grayscale(1);
    margin-top: 0;
    opacity: 1;
    z-index: 99;
    position: relative;
    padding-bottom: 0;
}

.Reservation-Calendar .Is-Selecting {
    animation-duration: 1.5s;
    animation-name: pulseSelectionParent;
    animation-iteration-count: infinite;
}

@keyframes pulseSelectionParent {
    from {
        --pulseSelectionTimeline: 0%;
    }
    to {
        --pulseSelectionTimeline: 100%;
    }
}


.Reservation-Calendar .Is-Selecting td:not(.Out-of-Active-Selection):not(.Blocked-Selection) {
    animation-duration: 1.5s;
    animation-name: pulseSelection;
    animation-iteration-count: infinite;
    animation-timeline: var(--pulseSelectionTimeline);
}

@keyframes pulseSelection {
    0% {
        background: rgba(221, 255, 204, 0.8);
    }
    50% {
        background: rgba(221, 255, 204, 0.95);
    }
    100% {
        background: rgba(221, 255, 204, 0.8);
    }
}