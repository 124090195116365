
.User-Note {
  padding: 3px 15px 3px 5px;
  margin: 0 2px;
  color: white;
  text-shadow: 1px 1px 1px black;
  width: auto;
  display: inline-block;
  border-radius: 5px;
}

.Message-Successful {
  border-left: 3px solid #009912;
  background: linear-gradient(45deg, #009912, transparent);
}

.Message-Warning {
  border-left: 3px solid #E0942C;
  background: linear-gradient(45deg, #E0942C, transparent);
}

.Message-Progress {
  border-left: 3px solid #2c87ff;
  background: linear-gradient(45deg, #2c87ff, transparent);
}

.Message-Failure {
  border-left: 3px solid #991200;
  background: linear-gradient(45deg, #991200, transparent);
}

@media (max-width: 767px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}
