.Common-Page .Content-Wrapper.Common-Index {
    background: #191919;
    clear: both;
    font-size: 14px;
    line-height: 20px;
    margin-top: 3rem;
    padding: 1rem 0 2rem;
    text-align: left;
}

.Common-Page .Content-Wrapper.Common-Index .Title {
    color: #ff3300;
    margin-top: 0;
    padding-bottom: 10px;
    padding-left: 15px;
    text-align: left;
}

.Common-Page h1, .Common-Page h2 {
    font-size: 32px;
    line-height: 34px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin: 0 0 30px 0;
    color: white;
}

.Common-Page .Feed a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
}

.Common-Page article p {
    margin: 0 0 10px;
}

.Common-Page article ul li::marker {
    color: #ff3300;
}

.Common-Page article p strong, .Common-Page article h4 strong, .Common-Page article h4 {
    color: #ff3300;
    font-weight: bold;
}

.Common-Page article a {
    color: #ff3300;
    text-decoration: none;
}

.Common-Page article {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    line-height: 26px;
    display: block;
    text-align: left;
    max-width: 900px;
    height: auto;
    margin: 0 auto 30px auto;
}

.Content-Header-Image {
    position: relative;
    padding-bottom: 15px;
    --article-header-img: none;
}

.Content-Header-Image:has(img[width="750"])::before, .Content-Header-Image:has(img[width="512"])::before {
    background-image: var(--article-header-img);
    filter: blur(15px);
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 0;
}

.Common-Page .Content-Wrapper {
    height: auto;
}

.Common-Page .Content-Wrapper .Content-Header-Image > img {
    max-width: 100%;
    height: auto;
    position: relative;
}

.Common-Page .Content-Community .Community-Actions {
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    max-width: 5rem;
}

.Common-Page .Content-Community button:active {
    box-shadow: 0 0 2px #000;
    color: #fff;
}

.Common-Page .Content-Community button:active svg {
    -webkit-animation-name: fa-shake;
    animation-name: fa-shake;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
    animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, linear);
    animation-timing-function: var(--fa-animation-timing, linear);
}

.Common-Page .Content-Community button:disabled {
    background: white !important;
    color: #fc361d !important;
    box-shadow: 0 0 2px #000;
    cursor: not-allowed;
}

.Common-Page .Content-Community button {
    background: #fc361d !important;
    padding: 2px 6px;
    border: none;
    box-shadow: 3px 3px 2px black;
    border-radius: 3px;
    margin-right: 2px;
    cursor: pointer;
    color: white;
}

.Common-Page .Content-Community {
    padding: 1rem;
    white-space: nowrap;
    font-size: 14px;
    text-align: left;
}

.Common-Page .Content-Author-N-Date .Content-Author-Image img {
    max-width: 48px;
}

.Common-Page .Content-Author-N-Date .Content-Author-Image {
    display: block;
    margin: 0 auto 10px auto;
}

.Common-Page .Content-Author-N-Date {
    font-size: 10px;
    line-height: 12px;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: white;
    cursor: pointer;
    user-select: none;
}

.Common-Page .Content-Fold {
    text-align: left;
    height: auto;
}

.Common-Page .Content-Fold figure.image img {
    max-width: 100%;
    height: auto;
}

.Common-Page .Feed-Author-Column {
    font-size: 10px;
    line-height: 12px;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    min-width: 100px;
    max-width: 100px;
}

.Common-Page .Common-Index .Feed-Author-Column {
    float: right;
}

.Common-Page .Feed-Author-Column img {
    width: 32px;
    height: 32px;
    border-radius: 40px;
    box-shadow: 1px 1px 2px black;
}

.Common-Page .Feed-Entry {
    position: relative;
    border-top: 1px solid #565656;
    border-bottom: 1px solid #565656;
    margin: 0 15px;
    padding: 25px 0 15px 0;
}

.Common-Page .Feed-Topic-Column:first-child {
    padding-left: 80px;
}

.Common-Page .Feed-Topic-Column:first-child .Feed-Item-Image {
    left: 6px;
}

.Common-Page .Feed-Topic-Column .Feed-Item-Image {
    display: inline-block;
    margin-right: 10px;
    width: 64px;
    max-width: 66px;
    min-height: 64px;
    max-height: 66px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    background: repeating-linear-gradient(
            -45deg,
            #cccccc,
            #999999 10px,
            #666666 10px,
            #333333 20px
    );
}

.Common-Page .Feed-Topic-Column .Feed-Item-Image img {
    display: block;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    height: 64px;
    border-radius: 5px;
    overflow: hidden;
}

.Common-Page .Feed-Topic-Column {
    text-indent: 0;
    padding-left: 74px;
    vertical-align: middle;
    min-height: 74px;
    position: relative;
}

.Common-Page .Feed-Stats svg {
    color: #f30;
    margin-left: 10px;
}

.Common-Page .Feed-Stats svg:first-child {
    margin-left: 5px;
}

.Common-Page .Feed-Stats {
    background: #191919;
    bottom: -11px;
    float: right;
    font-size: 85%;
    margin-top: 5px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: right;
    z-index: 100;
}

.Common-Page .Card {
    color: white;
    background: #383838;
    border: 1px solid #383838;
    border-bottom: 18px solid #383838;
    margin-bottom: 20px;
}

.Common-Page .Card.Highlighted {
    background: #f30;
    border: 1px solid #f30;
    border-bottom: 18px solid #f30;
}

.Common-Page .Card .Card-Title {
    min-height: 36px;
    position: relative;
    padding: 10px;
    padding-right: 33%;
}

.Common-Page .Card .Card-Body {
    background: #191919;
    padding: 10px;
}

.Common-Page .Card .Card-Title-Right {
    position: absolute;
    right: 10px;
    top: 10px;
    display: inline-block;
    font-size: x-large;
    font-weight: bold;
}

.Common-Page .text-huge {
    font-size: 1.8em;
}

.Common-Page .text-big {
    font-size: 1.4em;
}

.Common-Page .text-small {
    font-size: .85em;
}

.Common-Page .text-tiny {
    font-size: .7em;
}

.Common-Page figcaption {
    font-style: italic;
    background: rgba(125, 125, 125, 0.2);
    padding: 0 3px;
    margin-top: -8px;
    width: 100%;
}

.Common-Page .Draggable-List-Select {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    user-select: none;
}

.Common-Page .Draggable-List-Select li {
    background: rgb(240, 240, 240);
    padding: 3px;
    margin: 5px 2px;
    cursor: grab;
}

.Common-Page .Draggable-List-Select li.Draggable-List-Divider {
    cursor: not-allowed;
    font-size: small;
    border-top: 1px solid #666;
    margin-top: 10px;
}

.Common-Page .Draggable-List-Select li svg {
    margin: 0 5px;
}

.Common-Page.Content-Editor-Modal .Dynamic-Image-Slider ul::before {
    content: "---------------- Kuvaslider ----------------";
    position: absolute;
    top: 0;
    left: 0;
    color: white !important;
}

.Common-Page.Content-Editor-Modal .Dynamic-Image-Slider ul::after {
    content: "---------------- /Kuvaslider ----------------";
    position: absolute;
    bottom: 0;
    right: 0;
    color: white !important;
}

.Common-Page.Content-Editor-Modal .Dynamic-Image-Slider ul {
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    list-style-type: circle;
    position: relative;
}

.Common-Page.Content-Editor-Modal .Dynamic-Image-Slider ul li img {
    max-width: 128px;
}

.Common-Page:not(.Content-Editor-Modal) .Dynamic-Image-Slider .image img {
    display: block;
    text-align: unset;
    margin: 2px auto;
}

.Common-Page:not(.Content-Editor-Modal) .Dynamic-Image-Slider ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
}

.Common-Page:not(.Content-Editor-Modal) .Dynamic-Image-Slider li {
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
}

.Common-Page:not(.Content-Editor-Modal) .Dynamic-Image-Slider li {
    display: none;
}

.Common-Page:not(.Content-Editor-Modal) .Dynamic-Image-Slider li:first-child {
    max-width: unset;
    display: block;
}

.Common-Page:not(.Content-Editor-Modal) .Dynamic-Image-Slider ul li img {
    max-width: 100%;
    aspect-ratio: auto !important;
    width: auto !important;
    height: auto !important;
}

.Common-Page:not(.Content-Editor-Modal) .Dynamic-Image-Slider ul li::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: rgba(128, 128, 128, 0.5);
    animation: NextImageCountdown 15s linear forwards;
}

@keyframes NextImageCountdown {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}

.Common-Page table td figure.media, .Common-Page table td figure.image, .Common-Page figure.table {
    margin: 0;
}

.Common-Page figure.table table td {
    padding: 2px;
}

.Common-Page .Flex-Row, .Common-Modal .Flex-Row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
}

.Common-Page .Flex-Column, .Common-Modal .Flex-Column {
    flex: 1 1;
    margin: 0;
    padding: 5px;
    box-sizing: border-box;
}

.Align-Right {
    text-align: right;
}

.Youtube-Embed {
    text-align: center;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
}

.Common-Page div.Content-Column:only-child {
    max-width: calc(100vw - 20vw);
    width: calc(100vw - 20vw);
    height: auto;
}

.Common-Page div.Content-Column img[class*="wp-image-"] {
    max-width: 100%;
}

header:has(.Broadcast-Info) + main .Content-Column {
    margin-top: 25px;
}

.Content-Column .Community-Pick-Box[data-position="home"], .Content-Column .Community-Pick-Box[data-position="news"] {
    display: none;
}

.Common-Page .Content-Column table td[style*="background-color:rgb(249, 249, 249);"], .Common-Page .Content-Column table td[style*="background-color:rgb(255, 255, 255);"],
.Common-Page.Content-Editor-Modal table td[style*="background-color:rgb(249, 249, 249);"], .Common-Page.Content-Editor-Modal table td[style*="background-color:rgb(255, 255, 255);"] {
    color: #333;
}

@media (max-width: 767px) {
    .Common-Page figure.table tbody {
        display: flex;
    }

    .Common-Page figure.table table {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
    }

    .Common-Page figure.table tr {
        display: flex;
        flex-direction: column;
    }

    .Common-Page figure.table td {
        display: flex;
    }

    .Common-Page .Content-Fold article img.size-full {
        max-width: 100%;
        height: auto;
    }

    .Content-Column .Community-Pick-Box[data-position="home"], .Content-Column .Community-Pick-Box[data-position="news"] {
        display: block;
    }

    .Common-Page .Card .Card-Title-Right {
        position: relative;
        top: 0;
        left: 0;
    }
}
