.Broadcast-Info {
    position: relative;
    top: 103px;
    background: transparent;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: .9rem;
    font-weight: 700;
    height: 32px;
    left: 10vw;
    line-height: 32px;
    overflow: hidden;
    padding-left: 6px;
    text-align: left;
    width: 80vw;
    word-break: keep-all;
    z-index: 900;
    border-bottom: 1px solid #4F4F4F;
    padding-bottom: 3px;
    margin-top: -32px;
}

.Broadcast-Info:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 70px;
    background: #191919;
    z-index: 99;
}

.Broadcast-Info span {
    z-index: 102;
    display: block;
    position: relative;
    font-size: 12px;
}

.Broadcast-Info label {
    color: #fff;
    display: inline-block;
    font-family: Montserrat,sans-serif;
    font-size: 11px;
    font-weight: 700;
    left: 0;
    line-height: 11px;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
    user-select: none;
    z-index: 101;
    background: #191919;
}

.Broadcast-Info label a {
    color: #fff;
    text-decoration: none;
    background: #FF3300;
    padding: 6px 17px;
}

.Broadcast-Info > a {
    background: #191919;
    padding: 5px 3px;
    position: absolute;
    right: 0;
    vertical-align: middle;
}

.Broadcast-Info .Title-Scroll {
    /*position: absolute;
    top: 3rem;
    left: 0;*/
    padding-left: 6px;
    width: calc(80vw - 76px);
    display: inline-block;
    height: 32px;
    word-break: keep-all;
    white-space: nowrap;
    cursor: pointer;
    background: #191919;

    /*-moz-transform: translateX(220px);
    -webkit-transform: translateX(220px);
    transform: translateX(220px);*/
    user-select: none;

    /*-moz-animation: title-animation 15s linear infinite;
    -webkit-animation: title-animation 15s linear infinite;
    animation: title-animation 15s linear infinite;*/
}

@-moz-keyframes title-animation {
    from { -moz-transform: translateX(220px); }
    to { -moz-transform: translateX(calc(-100% + 34px)); }
}

@-webkit-keyframes title-animation {
    from { -webkit-transform: translateX(220px); }
    to { -webkit-transform: translateX(calc(-100% + 34px)); }
}

@keyframes title-animation {
    from {
        -moz-transform: translateX(220px);
        -webkit-transform: translateX(220px);
        transform: translateX(220px);
    }
    to {
        -moz-transform: translateX(calc(-100% + 34px));
        -webkit-transform: translateX(calc(-100% + 34px));
        transform: translateX(calc(-100% + 34px));
    }
}

@media (max-width: 767px) {
    .Broadcast-Info {
        left: 1vw;
        width: 97vw;
        top: 115px;
    }

    header:has( > .Broadcast-Info) + main .Content {
        margin-top: 90px;
        display: block;
    }
}
