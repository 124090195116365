.Logo-Navigation-Wrapper {
  max-width: 1550px;
  margin: 0 auto;
}

.Logo-Navigation {
  background-image: url(../images/header-logo.png);
  display: block;
  height: 85px;
  width: 366px;
}

header > .Logo-Navigation-Wrapper > a {
  z-index: 901;
  position: relative;
  display: block;
  height: 85px;
  left: calc(10vw - 38px);
  top: 0;
  width: 366px;
}

header > .Logo-Navigation-Wrapper > a {
  animation-name: rotateAnimation;
  animation-duration: 1ms;
  animation-direction: alternate;
  animation-timeline: scroll(block nearest);
}

@keyframes rotateAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  5% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@media (max-width: 767px) {
  .Logo-Navigation-Wrapper {
    width: calc(100% - 25px);
  }
  .Logo-Navigation {
    height: 34px;
    width: 300px;
    background-position: -39px -37px;
  }
  header > .Logo-Navigation-Wrapper > a {
    top: 60px;
    height: 34px;
    margin: 0 auto;
    width: 300px;
  }
}
