.Visitor-Navigation {
  color: #fff;
  display: block;
  height: 30px;
  padding: 0.2rem 1rem;
  position: relative;
  right: 0;
  text-align: right;
  text-shadow: 1px 1px 2px #333;
  top: -120px;
  width: auto;
  z-index: 900;
  max-width: 1550px;
  margin: 0 auto;
}

.Visitor-Navigation a {
  color: white !important;
  text-decoration: none !important;
  border-right: 2px solid white;
  margin-right: 20px;
  padding-right: 7px;
}

.Visitor-Navigation small {
  margin-left: 0.3rem;
  font-weight: bold;
}

.UserModal-link {}

.Has-Unread.Visitor-Navigation-Notifications::before {
  color: #f30;
  content: attr(data-count);
  display: block;
  right: -5px;
  position: absolute;
  bottom: -3px;
  font-size: 13px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.99);
  border-radius: 10px;
  width: 12px;
  height: 12px;
  overflow: visible;
  text-align: center;
  vertical-align: middle;
  line-height: 12px;
  user-select: none;
  z-index: 100;
}

.Has-Unread.Visitor-Navigation-Notifications[data-count="0"]::before, .Has-Unread.Visitor-Navigation-Notifications[data-count=""]::before {
  display: none;
}

.Visitor-Navigation-Notifications {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Visitor-Navigation-Notifications-Dropdown ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.Visitor-Navigation-Notifications-Dropdown ul li a {
  border-right: none;
}

.Visitor-Navigation-Notifications-Dropdown ul li svg {
  padding-right: 5px;
}

.Visitor-Navigation-Notifications-Dropdown .Notification-Read {
  opacity: 0.9;
}

.Visitor-Navigation-Notifications-Dropdown ul li {
  margin-left: 0;
  min-width: 380px;
  text-indent: -20px;
  padding-left: 20px;
}

.Visitor-Navigation-Notifications-Dropdown ul li + li {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #f0f0f0;
}

.Visitor-Navigation-Notifications-Dropdown {
  background: #0f0f0f;
  height: auto;
  right: 12px;
  min-height: 20px;
  position: absolute;
  top: calc(100% + 2px);
  width: auto;
  max-width: 400px;
  padding: 5px 5px;
  text-align: left;
  font-size: 90%;
}

.Has-Unread {
  cursor: pointer;
}

.User-Profile-Avatar {
  shape-rendering: geometricprecision;
  height: 32px;
  image-rendering: pixelated;
  vertical-align: middle;
  width: 32px;
  border-radius: 25px;
}

.Visitor-Navigation .UserModal-link > svg {
  vertical-align: middle;
  padding: 8px 0;
}


@media (max-width: 767px) {
  .Visitor-Navigation {
    right: 0;
    top: -30px;
  }
}
