footer {
    background: #1e2024;
    display: flex;
    flex-direction: column;
}

footer a {
    text-decoration: none;
    font-size: 11px;
    line-height: normal;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    margin: 0;
    color: white;
    padding: 0 25px;
}

footer .Footer-Main {
    padding: 80px 0;
    min-height: 15rem;
}

footer .Footer-Main .Footer-Info {
    text-align: left;
}

footer .Footer-Main .Footer-Info a {
    padding: 0;
}

footer .Footer-Main > div  > div {
    margin-top: 30px;
    vertical-align: top;
    padding-left: 8px;
}

footer .Footer-Main .Footer-Contact, footer .Footer-Main .Footer-Sponsor {
    padding-left: 34px;
    background-image: url(../images/kuulokkeet.png);
    background-repeat: no-repeat;
    background-position: 0 0;
}

footer .Footer-Main .Footer-Sponsor {
    background-image: url(../images/peliohjain.png);
}

footer .Footer-Main .Footer-Social ul li a {
    margin: 0;
    color: #6b6d70;
    padding-right: 10px;
}

footer .Footer-Main .Footer-Social ul li {
    display: inline-block;
    vertical-align: middle;
}

footer .Footer-Main .Footer-Social ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

footer .Footer-Main > div a {
    color: #6b6d70;
    font-size: 9px;
    line-height: normal;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    margin: 0;
}

footer .Footer-Main h4 {
    color: #fff;
    font-size: 9px;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.1;
}

footer .Footer-Main .Footer-Title {
    font-size: 14px;
    line-height: normal;
    color: #fff;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    margin: 0 0 25px;
}

footer p.Footer-Content {
    color: #9a9da2;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 10px;
}

footer .Logo {
    text-align: center;
    padding-top: 0;
    width: 134px;
    height: 170px;
    vertical-align: middle;
    border: 0;
    display: inline-block;
}

footer ul.Footer-Menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

footer ul.Footer-Menu li {
    display: inline-block;
    vertical-align: middle;
}

footer .Footer-Menu-Wrapper {
    text-align: center;
    max-width: 1070px;
    margin: 0 auto;
    -webkit-clip-path: polygon(0 0,100% 0%,94% 100%,6% 100%);
    clip-path: polygon(0 0,100% 0%,94% 100%,6% 100%);
    background: #1e2024;
    padding: 20px 0;
    margin-top: -1px;
}

.Footer-Bottom-Bar {
    background: var(--pelcom-red);
    clear: both;
    position: relative;
    top: -1px;
}

.Footer-Bottom-Bar > div, .Footer-Main {
    width: calc(100% - 30px);
    max-width: 1170px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

footer .Latest-News-List a:any-link {
    color: white;
    padding-left: 0;
}

footer .Latest-News-List li::marker {
    color: var(--pelcom-red);
}

footer ul.Latest-News-List {
    text-align: left;
}

@media (max-width: 767px) {
    .Footer-Info {
        margin-top: 2rem;
        padding: 0.5rem;
    }
    footer .Footer-Bottom-Bar {
        overflow: hidden;
    }
    footer .Footer-Bottom-Bar > div.Footer-Menu-Wrapper {
        transform: scale(1.03);
        max-width: 90vw;
    }
}