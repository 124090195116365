
.Ad-Box {
    padding: 15px 0;
}

.Ad-Box img {
    max-width: 100%;
}

@media (max-width: 767px) {
    .Ad-Box figure.image {
        padding: 0;
        margin: 0;
    }

    .Ad-Box img {
        height: auto;
    }
}
