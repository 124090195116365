
.Content-Column .Slider {
    background-repeat: no-repeat;
    background-size: cover;
    height: 420px;
    position: absolute;
    text-align: center;
    vertical-align: bottom;
    width: 750px;
    display: block;
    transition: filter 0.3s;
    filter: blur(0);
    cursor: pointer;
    user-select: none;
}

.Content-Column .Slider-Wrapper a.Next-Arrow:hover, .Content-Column .Slider-Wrapper a.Previous-Arrow:hover {
    background: #f30;
}

.Content-Column .Slider-Wrapper a.Next-Arrow:before, .Content-Column .Slider-Wrapper a.Previous-Arrow:before {
    content: ">";
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 1rem;
    background: #f30;
}

.Content-Column .Slider-Wrapper a.Previous-Arrow:before {
    content: "<";
}

.Content-Column .Slider-Wrapper a.Next-Arrow, .Content-Column .Slider-Wrapper a.Previous-Arrow {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    height: 20px;
    z-index: 800;
    background: #292c31;
    border-radius: 2px;
    color: white;
    display: block;
    text-decoration: none;
    font-weight: bold;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    font-size: 1rem;
    text-align: center;
    vertical-align: middle;
    box-shadow: 1px 1px 1px #444;
}

.Content-Column .Slider-Wrapper a.Previous-Arrow {
    right: 50px;
}

.Content-Column .Slider a.SliderStory-link {
    top: calc(100% + 20px);
    color: #fff;
    font-weight: 700;
    left: 10px;
    position: absolute;
    text-align: left;
    text-decoration: none;
    width: calc(720px - 2rem);
}

.Content-Column .Slider-Wrapper {
    background: #191919;
    border-radius: 3px;
    height: 530px;
    margin-bottom: 25px;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    overflow-y: hidden;
}


@media (max-width: 767px) {
    .Content-Column .Slider a.SliderStory-link {
        width: calc(100% - 2rem);
        top: calc(100% + -45px);
        background: rgba(125, 125, 125, 0.3);
    }

    .Content-Column .Slider-Wrapper, .Content-Column .Slider {
        width: 100%;
        height: auto;
        min-height: 185px;
    }

    .Content-Column .Slider {
        background-size: contain;
        background-position: center;
    }
}
