.Modal-Wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}

.ImageUploadModal {
    background: #1e2024;
    border-radius: 3px;
    box-shadow: 10px 10px 10px #111;
    width: 80vw;
    max-width: calc(100vw - 20vw - 3rem);
    position: fixed;
    top: 10rem;
    left: 10vw;
    min-height: 60vh;
    max-height: calc(100vh - 60vw - 10rem - 2rem);
    overflow-y: auto;
    padding: 1rem;
    font-size: 1.1rem;
    text-align: left;
}

.ImageUploadModal .Right-Column {
    text-align: left !important;
}

.ImageUploadModal .Right-Column ul {
    list-style: disc;
    color: var(--pelcom-red);
    font-size: 80%;
}

.ImageUploadModal .Right-Column ul li {
    color: white;
}

.ImageUploadModal button {
    margin: 0 8px;
}

@media (max-width: 767px) {
    .ImageUploadModal {
        top: 2vh;
        left: 5vw;
        max-height: 90vh;
        max-width: 95vw;
    }
}
