.Community-Box-Feed[data-fullpage="true"] {
    max-height: 100vh;
    overflow: auto;
    scrollbar-color: var(--pelcom-red) #0f0f0f;
    scrollbar-width: thin;
}

.Community-Box-Feed a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}

.Community-Box-Feed .Community-Box-Feed-Author-Column {
    font-size: 10px;
    line-height: 12px;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    min-width: 100px;
    max-width: 100px;
}

.Community-Box-Feed .Community-Box-Feed-Topic-Column:first-child {
    padding-left: 80px;
}

.Community-Box-Feed .Community-Box-Feed-Topic-Column:first-child .Community-Box-Feed-Item-Image {
    left: 6px;
}

.Community-Box-Feed .Community-Box-Feed-Topic-Column .Community-Box-Feed-Item-Image {
    display: inline-block;
    margin-right: 10px;
    width: 66px;
    min-width: 64px;
    max-width: 66px;
    min-height: 64px;
    max-height: 66px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    background: repeating-linear-gradient(
            -45deg,
            #cccccc,
            #999999 10px,
            #666666 10px,
            #333333 20px
    );
}

.Community-Box-Feed .Community-Box-Feed-Topic-Column .Community-Box-Feed-Item-Image img {
    display: block;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    height: 64px;
}

.Community-Box-Feed .Community-Box-Feed-Author-Column img {
    width: 32px;
    height: 32px;
    border-radius: 40px;
    box-shadow: 1px 1px 2px black;
}

.Community-Box-Feed .Community-Box-Feed-Topic-Column {
    text-indent: 0;
    padding-left: 74px;
    vertical-align: middle;
    min-height: 74px;
    position: relative;
}

.Community-Box-Feed .Community-Box-Feed-Stats svg, .Community-Box-Feed .Community-Box-Feed-Time svg {
    color: #f30;
    margin-left: 10px;
}

.Community-Box-Feed .Community-Box-Feed-Stats svg:first-child, .Community-Box-Feed .Community-Box-Feed-Time svg:first-child {
    margin-left: 5px;
}

.Community-Box-Feed .Community-Box-Feed-Stats {
    background: #191919;
    bottom: -11px;
    float: right;
    font-size: 85%;
    margin-top: 5px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: right;
    z-index: 100;
}

.Community-Box-Feed .Community-Box-Feed-Time {
    background: #191919;
    bottom: -11px;
    float: right;
    font-size: 85%;
    margin-top: 5px;
    padding: 0;
    position: absolute;
    text-align: left;
    left: 0;
    z-index: 100;
}

.Community-Box-Feed .Community-Box-Feed-Entry {
    position: relative;
    border-top: 1px solid #565656;
    border-bottom: 1px solid #565656;
    margin: 0 15px;
    padding: 25px 0 15px 0;
}

.Content-Column .Community-Box:first-child .Community-Box-Feed-Author-Column {
    position: absolute;
    right: 0;
    top: calc(50% - 26px);
}

.Content-Column .Community-Box:first-child .Community-Box-Feed-Entry {
    padding-right: 105px;
}

.Community-Box-Feed .Community-Box-Feed-Time {
    padding-right: 5px;
}

.Community-Box-Feed .Community-Box-Feed-Stats, .Community-Box-Feed .Community-Box-Feed-Time, .Community-Box-Feed .Community-Box-Feed-Author-Column {
    user-select: none;
}

.Community-Box-Feed-Show-Latest-Button-Wrapper {
    float: right;
}

.Community-Box-Feed-Show-Latest-Button-Wrapper a.In-Place-Button {
    text-decoration: none;
    box-shadow: none;
    padding: 3px 8px;
    margin-right: 5px;
    font-size: 90%;
}

.Community-Box-Feed-Latest-Comment svg {
    color: var(--pelcom-red);
}

.Community-Box-Feed-Latest-Comment {
    margin: 10px;
    text-overflow: ellipsis;
    height: 18px;
    width: 90%;
    overflow: hidden;
}

@media (min-width: 768px) {

}


@media (max-width: 767px) {

}
