.Modal-Wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}

.UserModal {
    background: #1e2024;
    border-radius: 3px;
    box-shadow: 10px 10px 10px #111;
    width: 80vw;
    max-width: calc(100vw - 20vw - 3rem);
    position: fixed;
    top: 110px;
    left: 10vw;
    min-height: 70vh;
    max-height: calc(100vh - 70vh - 220px);
    overflow-y: auto;
    padding: 10px;
    font-size: 1.1rem;
    text-align: left;
    scrollbar-color: #ff3300 #0f0f0f;
    scrollbar-width: thin;
}

.UserModal a, .UserModal a:visited {
    color: white !important;
    text-decoration: none;
}

.UserModal .User-Profile-Image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(circle at center, transparent 120px, rgba(0, 0, 0, 0.7) 121px);
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.UserModal .User-Profile-Image img {
    width: 100%;
    margin-right: 10px;
}

.UserModal .User-Profile-Image {
    cursor: pointer;
    position: relative;
}

.Profile-SubTab {
    background: #0f0f0f;
    color: white !important;
    text-transform: uppercase;
    display: block;
    padding: 10px;
    font-size: 120%;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.Discord-Sso svg {
    line-height: 23px;
    vertical-align: top;
    padding: 3px 6px;
}

.Twitch-Sso svg {
    line-height: 28px;
    vertical-align: top;
    padding: 6px;
}

.User-Info-Field-Title {
    display: inline-block;
    min-width: 110px;
    padding-right: 10px;
    text-align: right;
    font-size: 90%;
    font-weight: bold;
}

.Active-Profile-SubTab {
    background: #f30;
}

.UserModal .User-Profile-Container {
    display: flex;
    height: 100%;
}

.UserModal .User-Profile-Container h2, .UserModal .User-Profile-Container h3 {
    color: #f30;
}

.UserModal .User-Profile-Container .Left-Column h2 {
    margin-top: 0;
    margin-bottom: 10px;
}

.UserModal .User-Profile-Container ul {
    list-style-type: none;
    padding-left: 0;
}

.UserModal .User-Profile-Container li {
    padding-inline-start: 0;
}

.UserModal .User-Profile-Container .Left-Column {
    width: 25%;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    vertical-align: top;
}

.UserModal .User-Profile-Container .Middle-Column {
    flex-grow: 1;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    vertical-align: top;
}

.UserModal .User-Profile-Container .Right-Column {
    width: 20%;
    padding: 10px;
    box-sizing: border-box;
    text-align: right;
    vertical-align: top;
}

.UserModal .In-Place-Button.Register-Button {
    padding: 5px 10px;
    font-size: medium;
    box-shadow: none;
    cursor: pointer;
}

.UserModal .Full-Width-Button.Login-Button {
    box-shadow: none;
    cursor: pointer;
}

.UserModal .Register-Actions-Column p {
    margin-bottom: 0;
}

.UserModal .Register-Actions-Column ul {
    margin-top: 5px;
}

.UserModal .Register-Mock-Profile h2 {
    text-align: center;
    margin-bottom: 0;
}

.UserModal .Register-Mock-Profile hr {
    border-color: #666;
}

.UserModal .Register-Profile-Benefits h3 {
    margin-bottom: 5px;
}

.UserModal .Register-Profile-Benefits ul {
    list-style-type: dics;
    padding-left: 15px;
    margin-top: 0;
}

.UserModal .Register-Profile-Benefits ul li::marker {
    color: var(--pelcom-red);
}


@media (max-width: 767px) {
    .UserModal {
        top: 2vh;
        left: 5vw;
        max-height: 90vh;
        max-width: 95vw;
    }

    .UserModal .User-Profile-Container {
        display: block;
    }

    .UserModal .User-Profile-Container .Register-Mock-Profile {
        text-align: center !important;
    }

    .UserModal .User-Profile-Container .Register-Mock-Profile img {
        max-width: 125px;
    }

    .UserModal .User-Profile-Container .Register-Mock-Profile,
    .UserModal .User-Profile-Container .Register-Profile-Benefits,
    .UserModal .User-Profile-Container .Register-Actions-Column {
        width: 100% !important;
    }

    .UserModal .User-Profile-Container .Left-Column, .UserModal .User-Profile-Container .Right-Column {
        width: 100%;
    }
}
