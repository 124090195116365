.Links-Navigation {
  color: #fff;
  display: block;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding-left: 0;
  position: relative;
  text-align: justify;
  top: 30px;
  width: 80vw;
  max-width: 1550px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
}

.Links-Navigation li.Active-Link {
  border-bottom: 2px solid #f30;
}

.Links-Navigation li.Active-Link a, .Links-Navigation li.Active-Link a:visited, .Links-Navigation li.Active-Link a:active {
  color: #f30 !important;
}

.Links-Navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.Links-Navigation li {
  display: inline-block;
  margin-right: 5px;
}

.Links-Navigation li:last-child {
  margin-right: 0;
}

.Links-Navigation li a {
  text-decoration: none;
  color: white;
  user-select: none;
}

.Links-Navigation .Divider {
  display: none;
}

.Links-Navigation-Mobile-Menu, .Links-Navigation-Mobile-Menu-Close {
  display: none;
}

@media (max-width: 767px) {
  .Links-Navigation {
    display: block;
    position: absolute;
    left: 0.25rem;
    top: 38px;
    background: hsla(0,0%,5%,.95);
    border-radius: 10px;
    border-top-left-radius: 0;
    z-index: 901;
    height: auto;
    margin: 0 1vw;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    opacity: 0.8;
    width: 0;
    transition: opacity 300ms linear 0ms, width 100ms linear 300ms, padding 100ms linear 300ms;
  }

  .Links-Navigation ul {
    flex-direction: column;
  }

  .Links-Navigation-Mobile-Menu, .Links-Navigation-Mobile-Menu-Close {
    background: #333;
    border: none;
    border-radius: 1px;
    display: block;
    left: 5px;
    padding: 5px;
    position: absolute;
    top: 5px;
    z-index: 902;
    zoom: 1.4;
  }

  .Links-Navigation-Mobile-Menu-Close {
    display: none;
    z-index: 903;
    background: var(--pelcom-red);
  }

  .Links-Navigation-Mobile-Menu:is([style*="display:none"], [style*="display: none"]) {
    background: hsla(0,0%,5%,.9);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .Links-Navigation-Mobile-Menu-Close:is([style*="display:block"], [style*="display: block"]) ~ .Links-Navigation {
    transition: opacity 300ms linear 0ms, width 100ms linear 300ms, padding 100ms linear 300ms;
    width: 50%;
    opacity: 1;
    padding: 0.5rem;
  }

  .Links-Navigation-Mobile-Menu-Close:is([style*="display:block"], [style*="display: block"]) ~ .Links-Navigation li.Divider {
    display: none;
  }

  .Links-Navigation-Mobile-Menu-Close:is([style*="display:block"], [style*="display: block"]) ~ .Links-Navigation li {
    display: block;
  }

  .Links-Navigation li a {
    width: 100%;
    display: inline-block;
  }
}
