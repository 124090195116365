.NewsBox-Article-Image {
    display: block;
    width: 100%; /* Ensure full width */
    aspect-ratio: 16 / 9; /* Maintain a consistent aspect ratio */
    object-fit: cover; /* Crop the image to fit within the defined aspect ratio */
    border-radius: 5px; /* Optional: adds a subtle rounded corner effect */
    overflow: hidden; /* Ensures content outside is cropped */
}

.NewsBox-Article-List-Container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: #ff3300 #0f0f0f;
    scrollbar-width: thin;
}

NewsBox-Article-List-Container::-webkit-scrollbar {
    height: 8px;
}

NewsBox-Article-List-Container::-webkit-scrollbar-track {
    background-color: #0f0f0f;
}

NewsBox-Article-List-Container::-webkit-scrollbar-thumb {
    background-color: #ff3300;
    border-radius: 10px;
}

NewsBox-Article-List-Container::-webkit-scrollbar-thumb:hover {
    background-color: #ff5733;
}

NewsBox-Article-List-Container::-webkit-scrollbar-thumb:active {
    background-color: #cc2900;
}

.NewsBox-Article-List {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.NewsBox-Article-List li a:any-link {
    color: #fff;
    text-decoration: none;
    display: flex;
    flex-direction: column; /* Stack image and text */
}

.NewsBox-Article-List li a span {
    display: -webkit-box;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    white-space: normal;
    max-height: 2.6em;
    line-height: 1.3em;
    -webkit-box-orient: vertical;
}

.NewsBox-Article-List li {
    background: #0e0e10;
    box-sizing: border-box;
    flex: 0 0 auto;
    margin-bottom: 10px; /* Adjust spacing between articles */
    max-width: 276px;
    padding: 10px;
}

div#InfiniteNewsBoxScroll + a.Full-Width-Button {
    text-decoration: none !important;
    margin: 5px 10px;
    max-width: calc(100% - 20px);
    text-align: center;
}

.NewsBox-Article-List-Container[data-scroll-direction="vertical"] {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
}

.NewsBox-Article-List-Container[data-scroll-direction="vertical"] .NewsBox-Article-List {
    flex-wrap: wrap;
}

.Community-Box:has(.NewsBox-Article-List-Container[data-scroll-direction="horizontal"]) {
    display: grid;
}

.NewsBox-Article-List-Item > a {
    position: relative;
}

.Content-Column .Label.NewsBox-Article-Date-Label {
    background: #32323280;
    border-radius: 0;
    display: block;
    left: 5px;
    position: absolute;
    top: 5px;
}

@media (max-width: 767px) {

}

